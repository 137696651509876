.Collapsible__trigger  {
	width: 100%;
	position: static;
	display: block;
	background-image: linear-gradient(to right bottom, #FF5F6D, #FFC371);
	height: 50px;
	color: white;
	margin: auto;
	vertical-align: middle;
	margin-bottom: auto;
	margin-top: auto;
	padding: 10px;
	cursor: pointer;
}

.statIzvestajLeftMenu {
	width: 75% !important;
	margin: auto !important;
}

.statParametriTable {
	width: 1100px;
	padding-top: 15px;
}

.statParametriTable .vrednost {
	width: 500px;
}

.statParametriTable label {
	text-align: right;;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.seletovanaKategorija {
	background-color: #17a2b8 !important;
	color: white !important;
}