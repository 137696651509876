/* Colors */
$white: #ffffff;
$orange: #eaab00;
$blue: #00a0da;
$dark-blue: #001864;
$red: #e64a0d;
$silver: #555454;
$green: #3e7c24;
$redred: #ff0000;

/* General */
$fontSize: 62.5%;
$bodyBgColor: #edf0f5;
$textColor: #333333;
$textSecondaryColor: #707070;
$borderRadius: 0.3rem;
$dividerColor: #e3e3e3;
$transitionDuration: 0.2s;
$maskBgColor: #424242;
