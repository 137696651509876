.layout-registri {
  @include transition(margin-left $transitionDuration);
  background-color: $bodyBgColor;
  color: $white;

  .p-messages-detail {
    white-space: pre-wrap;
  }

  .panel-registri {
    .p-dropdown {
      width: 100%;
    }

    .p-inputtext {
      width: 100%;
    }

    .p-multiselect {
      width: 100%;
    }

    .p-dialog {
      max-width: 70%;
      min-width: 60%;

      .p-datatable {
        .p-dropdown {
          width: auto;
        }
      }
    }
  }

  .p-datatable {
    .p-datatable-wrapper {
      .p-datatable-thead {
        .p-sortable-column {
          text-align: center;
        }
        .p-filter-column {
          .p-inputtext {
            width: 100%;
          }
        }
      }
    }
  }

  .p-toolbar-group-left {
    .p-button {
      margin-right: 10px;
    }
  }

  .panel-registri {
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;

    .p-panel-header {
      text-align: center;
      font-size: 18px;

      .p-panel-title {
        margin: auto;
        font-size: 18px;
      }
    }

    .column-align-center {
      text-align: center;
      font-size: 14px;
    }

    .p-button-label {
      font-weight: 600;
    }
  }

  .p-dialog {
    max-width: 80%;
    min-width: 50%;
    max-height: 90%;
    min-height: 80%;
  }

  .panel-registri-list {
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;
    min-width: 1000px;

    .p-panel-titlebar {
      text-align: center;
      font-size: 18px;
    }
  }
}
.p-log-container {
  text-align: center !important;
  .p-logo-image {
    width: 260px;
    height: 100px;
    margin-top: 40px;
    margin-left: 60px;
  }
}
