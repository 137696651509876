@media screen and (max-width: 800px) {
  .reactHtmlParser img {
    width: 100%;
  }
  .reactHtmlParser strong {
    font-weight: bold;
  }
}

@media screen and (min-width: 801px) {
  .reactHtmlParser img {
    width: 100%;
  }

  .reactHtmlParser strong {
    font-weight: bold;
  }
}
