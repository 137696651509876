.layout-pacijent {
  @include transition(margin-left $transitionDuration);
  background-color: $bodyBgColor;
  color: $white;

  .panel-pacijent {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;

    .p-panel-header {
      text-align: center;
      font-size: 18px;
    }
  }

  .p-toolbar-group-left {
    .p-button {
      margin-right: 10px;
    }
  }

  .p-dialog {
    max-width: 80%;
    min-width: 50%;
    max-height: 80%;
    min-height: 80%;

    .p-datatable {
      .p-dropdown {
        width: auto;
      }
    }
  }

  .p-radiobutton-label {
    margin-right: 15px;
  }
}

.pac-value {
  border-radius: 5px;
  border: 1px solid #d5d5d5;
  background-color: #f4f4f4;
}

.pac-icon {
  color: $dark-red !important;
  padding: 0.3rem !important;
  &:hover {
    opacity: 1;
    color: $white !important;
    background-color: $dark-red !important;
    border-color: $dark-red !important;
  }
}

.relevantni {
  padding: 0.4rem !important;
  border-radius: 5px;
  border: 1px solid #dc3545;
  color: #dc3545;
}

.nerelevantni {
  padding: 0.4rem !important;
  border-radius: 5px;
  background-color: #dc3545;
  border: 1px solid #dc3545;
  color: white;
}

.pac-lekar {
  margin-left: 0.5rem;
  align-self: center;
}
