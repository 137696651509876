.layout-not-found {
  @include transition(margin-left $transitionDuration);
  background-color: $bodyBgColor;
  color: $white;
  width: 40%;
  margin: 0 auto;
  margin-top: 150px;
  text-align: center;

  .p-code {
    font-size: 150px;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;
    opacity: 0.7;
  }

  .p-error-message {
    font-size: 40px;
    opacity: 0.7;
  }
}
