.layout-login {
  @include transition(margin-left $transitionDuration);

  display: flex;
  justify-content: center;

  .img_logo {
    float: left;
    width: 85px;
    height: 85px;
  }

  .p-button-icon-left {
    left: 8em;
  }

  .p-col-9 {
    padding: 0;
  }

  .p-card {
    position: relative;
    top: 250px;
    width: 450px;
    padding: 20px;
  }

  .app-name {
    font-size: 50px;
    text-align: center;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .margin-bottom-10px {
    margin-bottom: 10px;
  }
}
