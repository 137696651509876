.layout-sponsor {
  .panel-sponsor {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;

    .logo-img {
      height: 100%;
      width: 100%;
    }

    .margin-auto {
      margin-top: auto;
      margin-bottom: auto;
    }

    .display-sponsor {
      .margin-horizontal-40 {
        margin-left: 40px;
        margin-right: 40px;
        text-align: center;
      }
    }
  }

  .sponsor-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    width: 50%;
  }

  .small-logo {
    height: 30px;
  }

  .big-logo {
    height: 150px;
  }

  .text-align-center {
    text-align: center;
  }
}
