.layout-versions {
    @include transition(margin-left $transitionDuration);
    background-color: $bodyBgColor;
    color: $white;
  
    .panel-versions {
      .p-panel-header {
        background-color:moccasin;
      }

      .p-panel-title{
        margin:auto;
        font-size: 18px;
      }
    }

    .p-datatable{
      .p-datatable-wrapper{
        .p-datatable-thead{
          .p-sortable-column{
            text-align: center;
          }
          .p-filter-column{
            .p-inputtext{
              width: 100%;
            }
          }
        }
      }
    }

    .panel-vrednosti {
        .p-panel-header {
          background-color: moccasin;
        }

        .p-panel-title{
          margin:auto;
          font-size: 18px;
        }
      }

    .p-card{
        .p-card-body{
            .p-card-title{
                font-size: 1.4em;
            }
        }
        .p-button-danger{
          background: #dc3545;
          border: #c8c8c8;
          position: absolute;
        }

        .p-multiselect{
          width: 400px;
        }
    }

}