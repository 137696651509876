.layout-pacijent-arhiva {
  @include transition(margin-left $transitionDuration);
  background-color: $bodyBgColor;
  color: $white;

  .panel-pacijent-arhiva {
    .p-dropdown {
      width: 100%;
    }

    .p-inputtext {
      width: 100%;
    }

    .p-multiselect {
      width: 100%;
    }

    .p-dialog {
      max-width: 70%;
      min-width: 60%;

      .p-datatable {
        .p-dropdown {
          width: auto;
        }
      }
    }
  }

  .panel-pacijent-arhiva-list {
    padding: 1.2rem;

    .p-panel-header {
      text-align: center;
      font-size: 18px;
    }
  }

  .p-dialog {
    max-width: 80%;
    min-width: 50%;
    max-height: 90%;
    min-height: 80%;
  }

  .p-toolbar-group-left {
    .p-button {
      margin-right: 10px;
    }
  }
}
