.layout-user-profile {
  @include transition(margin-left $transitionDuration);
  background-color: $bodyBgColor;
  color: $white;
  width: 50%;

  .panel-user {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;

    .p-panel-header {
      text-align: center;
      font-size: 18px;
      .p-panel-title{
        margin:auto;
        font-size: 18px;
      }
    }

    .p-panel-content{
      height: 470px;
    }
    .p-toolbar-group-left{
      float: left;
    }

    .p-toolbar-group-right{
      float: right;
    }
  }


  .p-toolbar-group-left {
    .p-button {
      margin-right: 10px;
    }
  }

  .p-dialog {
    max-width: 40%;
    min-width: 30%;
    max-height: 40%;
    min-height: 30%;

    .p-datatable {
      .p-dropdown {
        width: auto;
      }
    }
  }
}
