$white: #ffffff;
$orange: #eaab00;
$blue: #00a0da;
$dark-blue: #001864;
$red: #e64a0d;
$silver: #555454;
$green: #6df533;
$dark-green: #018a11;
$dark-red: #dc3545;

$topbarLeftBgColor: #ed213a;
$topbarRightBgColor: #93291e;
$topbarItemBadgeBgColor: #ef6262;
$topbarItemBadgeColor: #ffffff;
$topbarItemColor: #001764;
$topbarItemHoverColor: #c3e8fb;
$topbarSearchInputBorderBottomColor: #ffffff;
$topbarSearchInputColor: #ffffff;
