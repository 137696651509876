.layout-message {
  @include transition(margin-left $transitionDuration);
  background-color: $bodyBgColor;
  color: $white;

  .panel-search {
    .p-dropdown {
      width: 100%;
    }

    .p-inputtext {
      width: 100%;
    }

    .p-calendar {
      width: 100%;
    }

    .text-align-right {
      text-align: right;
    }
  }

  .panel-message-list {
    padding-top: 14px;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;

    .p-panel-header {
      color: $white;
      text-align: center;
      background-color: $orange;
      font-size: 18px;
    }
  }

  .p-dialog {
    max-width: 95%;
  }
}
