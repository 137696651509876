.layout-admin {
  @include transition(margin-left $transitionDuration);
  background-color: $bodyBgColor;
  color: $white;

  .p-messages-detail {
    white-space: pre-wrap;
  }

  .panel-admin {
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;

    .p-panel-header {
      color: $white;
      text-align: center;
      background-color: $redred;
      font-size: 18px;
    }

    .p-inputtext {
      width: 100%;
      text-align: right;
    }

    .p-button {
      width: 100%;
    }

    .separator {
      width: 100%;
      border-top-style: solid;
      border-top-color: $blue;
      border-top-width: 2px;
      line-height: 0;
      padding: 0px;
      margin: 0px;
    }

    .text-align-right {
      text-align: right;
    }
  }
}
