.layout-parametri {
  @include transition(margin-left $transitionDuration);
  background-color: $bodyBgColor;
  color: $white;

  .panel-parametri {
    .p-dropdown {
      width: 100%;
    }

    .p-inputtext {
      width: 100%;
    }

    .p-multiselect {
      width: 100%;
    }
  }

  .panel-parametri-list {
    width: 600px;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;

    .p-panel-header {
      // color: $white;
      text-align: center;
      // background-color: $silver;
      font-size: 18px;
      .p-panel-title {
        margin: auto;
        font-size: 18px;
      }
    }
  }

  .p-toolbar-group-left {
    .p-button {
      margin-right: 10px;
    }
  }

  .p-dialog {
    max-width: 80%;
    min-width: 50%;
    max-height: 90%;
    min-height: 80%;
  }
}
