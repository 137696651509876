.layout-attribute {
  @include transition(margin-left $transitionDuration);
  background-color: $bodyBgColor;
  color: $white;

  .p-messages-detail {
    white-space: pre-wrap;
  }

  .panel-attribute {
    .p-dropdown {
      width: 100%;
    }

    .p-inputtext {
      width: 100%;
    }

    .p-multiselect {
      width: 100%;
    }

    .p-dialog {
      max-width: 70%;
      min-width: 60%;

      .p-datatable {
        .p-dropdown {
          width: auto;
        }
      }
    }
  }

  .p-datatable{
    .p-datatable-wrapper{
      .p-datatable-thead{
        .p-sortable-column{
          text-align: center;
        }
        .p-filter-column{
          .p-inputtext{
            width: 100%;
          }
        }
      }
    }
  }

  .p-toolbar-group-left {
    .p-button {
      margin-right: 10px;
    }
  }
  .p-dialog {
    max-width: 80%;
    min-width: 50%;
    max-height: 90%;
    min-height: 80%;
  }

  .panel-attribute-list {
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;

    .p-panel-header {
      text-align: center;
      font-size: 18px;
      .p-panel-title{
        margin:auto;
        font-size: 18px;
      }
    }
    .p-toolbar-group-left{
      float: left;
    }

    .p-toolbar-group-right{
      float: right;
    }
  }
}
