.Collapsible__contentInner-admin {
	margin-left: 20px;
}

.Collapsible__trigger-admin, .Collapsible__trigger-admin span  {
	width: 100%;
	position: static;
	display: block;
	color:black;
	background-image: none;
	margin: 0;
	vertical-align: top;
	margin-bottom: 0;
	margin-top: 0;
	padding: 0px;
	cursor: pointer;
}

.Collapsible__trigger-adminClosed span, .Collapsible__trigger-admin span {
	height: 40px;
}
