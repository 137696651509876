.multi-wrapper {
	border: 1px solid #ccc;
	border-radius: 3px;
	width: 1000px;
}

.buttons-multi-wrapper {
	width: 1000px;
}

.button-multi-wrapper {
	width: 50%;
	text-align: center;
	display: inline-block;
}

.headers-multi-wrapper {
	width: 1000px;
	border-bottom: 1px solid #ccc;
}

.header-multi-wrapper {
	width: 50%;
	text-align: center;
	display: inline-block;
	color: #4f4f4f;
	cursor: default;
	font-weight: 700;
	margin-bottom: 5px;
	padding: 5px 10px;
}

.search-multi-wrapper {
	width: 100%;
	display: inline-block;
	color: #4f4f4f;
	cursor: default;
	font-weight: 700;
}

.search-input {
	width: 100%;
	padding: 0;
}


.multi-wrapper .non-selected-wrapper {
    background: #fff;
    border-right: 1px solid #ccc;
}

.multi-wrapper .selected-wrapper {
    background: #fff;
}

.non-selected-wrapper, .selected-wrapper {
	box-sizing: border-box;
	display: inline-block;
	height: 500px;
	overflow-y: scroll;
	padding: 10px;
	vertical-align: top;
	width: 50%;
}

.multi-wrapper .header {
	color: #4f4f4f;
	cursor: default;
	font-weight: 700;
	margin-bottom: 5px;
	padding: 5px 10px;
	text-align: center;
}

.multi-wrapper .item {
    cursor: pointer;
    display: block;
    padding: 5px 10px;
}

.multi-wrapper a, .multi-wrapper a:hover, .multi-wrapper a:visited, .multi-wrapper a:active{
    text-decoration: none;
    color: #000;
}

.multi-wrapper a:hover {
	background: #ccc;
}
