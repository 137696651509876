.layout-header {
  height: auto;
  top: 0px;
  left: 0px;
  margin-bottom: 1rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 0px;
  color: $white;
  z-index: 999;
  right: 0;
  display: flex;
  width: 100%;
  background-color: $dark-red !important;
  @include clearfix();
  @include transition(left $transitionDuration);

  .p-menubar-start,
  .p-menubar-end {
    width: 50%;
  }

  .p-menu-button {
    margin-left: 2em;
    text-decoration: none;
    background-color: $dark-red;
    border-color: $dark-red;
    opacity: 0.7;
    &:hover {
      opacity: 1;
      background-color: $dark-red !important;
      border-color: $dark-red !important;
    }
  }

  .p-tieredmenu {
    width: auto;
  }

  .card {
    background-color: #dc3545;
  }

  .p-menubar {
    background-color: #dc3545;
    display: flex;
    width: 100%;

    .p-menubar-root-list {
      .p-menuitem:not(p.disabled) {
        background-color: #dc3545;
        font-size: 14px;
        .p-menuitem-link:not(p.disabled) {
          background-color: #dc3545;
          :hover {
            transform: scale(1.1);
            transform-origin: center;
          }
          .p-menuitem-text:not(p.disabled) {
            background-color: #dc3545;
            color: white;
            opacity: 0.7;
            font-size: 300;
          }
        }
        .p-submenu-list {
          .p-menuitem {
            .p-menuitem-link {
              background-color: white;
              .p-menuitem-text {
                background-color: white !important;
                color: black !important;
              }
            }
          }
        }
      }
    }
  }

  .p-menubar-custom {
    display: flex;
    width: 100%;
    .p-dropdown {
      width: 50%;
    }
  }

  .layout-centre-container {
    display: flex;
    align-items: center;
    .text {
      color: $white;
      margin-left: 2em;
      text-decoration: none;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
  }

  .layout-right-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .text {
      color: $white;
      margin-left: 2em;
      text-decoration: none;
      margin-right: 1em;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
    .text-small {
      font-size: 1em;
    }
  }

  .application-name {
    font-size: 1.5em;
    text-transform: uppercase;
    color: $white;
    margin-left: 0.5em;
    align-self: center;
    vertical-align: middle;
    text-decoration: none;
  }

  .layout-menu-button {
    cursor: pointer;

    text-decoration: none;
    color: $topbarItemColor;
    float: left;
    @include transition(color $transitionDuration);

    span {
      font-size: 2em;
    }

    &:hover {
      color: $topbarItemHoverColor;
    }
  }
}

.layout-topbar-menu {
  text-align: center;

  .layout-topbar-menuitem {
    position: relative;
    color: $topbarItemColor;
    display: inline-block;
    text-decoration: none;
    @include transition(color $transitionDuration);
    overflow: visible;
    text-align: center;
    user-select: none;

    a {
      color: $topbarItemColor;

      &:hover {
        color: $topbarItemHoverColor;
      }
    }

    span {
      &.menuitem-icon {
        line-height: 0;
        font-size: 1em;
        display: block;
      }

      &.menuitem-label {
        padding: 0px;
        font-size: 1em;
        display: block;
      }
    }
  }
}
