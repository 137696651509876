html {
  font-size: 80%;
}

input.field {
  width: 70%;
}

table {
  border-collapse: collapse;
}

td,
th {
  border: 1px solid #999;
  padding: 0.5rem;
  text-align: left;
}

/*Classes for creating dialogs*/

.modalDialog {
  position: fixed;
  font-family: Arial, Helvetica, sans-serif;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  opacity: 0;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  pointer-events: none;
}

.fade:target {
  opacity: 1 !important;
  pointer-events: auto;
}

.modalShow {
  display: block !important;
  padding-right: 17px;
}

.modal-header-1 {
  background-color: #2196f3;
  color: white;
}

.modal-header-1 .close {
  color: white;
  opacity: 1;
}

.modalDialog > div {
  width: 400px;
  position: relative;
  margin: 10% auto;
  padding: 5px 20px 13px 20px;
  border-radius: 10px;
  background: #fff;
  background: -moz-linear-gradient(#fff, #999);
  background: -webkit-linear-gradient(#fff, #999);
  background: -o-linear-gradient(#fff, #999);
}

body,
html {
  height: 100%;
  background-repeat: no-repeat;
  font-family: "Oxygen", sans-serif;
}

.main {
  margin-top: 70px;
}

h1.title {
  font-size: 50px;
  font-weight: 400;
}

hr {
  width: 10%;
  color: #fff;
}

.form-group {
  margin-bottom: 15px;
}

label {
  margin-bottom: 15px;
}

input,
input::-webkit-input-placeholder {
  font-size: 11px;
  padding-top: 3px;
}

.main-login {
  background-color: #fff;
  /* shadows and rounded borders */
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.main-center {
  margin-top: 30px;
  margin: 0 auto;
  max-width: 420px;
  padding: 40px 40px;
  position: relative;
  top: 180px;
  border: 1px solid red;
}

.shadow {
  -webkit-box-shadow: 3px 3px 5px 6px #ccc; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 3px 3px 5px 6px #ccc; /* Firefox 3.5 - 3.6 */
  box-shadow: 3px 3px 5px 6px #ccc; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

button {
  cursor: pointer;
}

.notification-container > span > div:first-of-type {
  margin-top: 65px !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  outline: none;
  margin-bottom: 0;
  vertical-align: text-bottom;
}
.switch input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}

label.switch {
  margin-left: 15px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 17px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #2196f3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.pageable {
  position: relative;
  min-height: 35px;
  margin-bottom: 5px;
}

.pageable-size {
  width: 100px;
  display: inline-block;
}

.pageable-links {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.pageable-links .pageable-links-arrow {
  height: 100%;
  background-color: #fff;
  margin: auto 3px;
  color: #2196f3;
  font-weight: bold;
  border: 2px solid #2196f3;
  text-align: center;
  vertical-align: middle;
}

.pageable-links .pageable-links-disable {
  height: 100%;
  background-color: #fff;
  margin: auto 3px;
  color: #d4d6d8;
  font-weight: bold;
  border: 2px solid #d4d6d8;
  text-align: center;
  vertical-align: middle;
  cursor: no-drop;
}

.pageable-links [class^="pageable-links"]:last-of-type {
  margin-right: 0;
}

.pageable-links .pageable-links-arrow:hover {
  background-color: #2196f3;
  color: #fff;
}

.btn-primary-light {
  background-color: #2196f3 !important;
}

.btn-outline-primary-light {
  color: #2196f3 !important;
  border: 1px solid #2196f3 !important;
}

.btn-outline-primary-light:hover {
  background-color: #2196f3 !important;
  color: white !important;
  border: 1px solid #2196f3 !important;
}

.listOfObject th {
  background-color: moccasin;
  border: none;
  text-align: center;
}

.listOfObject th:not(.action):not(:first-of-type) {
  border-left: 1px solid #999;
}

.listOfObject td {
  border: none;
  text-align: center;
}

.listOfObject td:not(.action):not(:first-of-type) {
  border-left: 1px solid #999;
}

.listOfObject tr:nth-child(even) {
  background: #f7f7f7;
}

.fa-primary-light {
  color: #2196f3 !important;
}

.nav-bar-left {
  float: left;
  height: 100vh;
  background-color: #dc3545;
}

.nav-bar-left a {
  color: rgba(255, 255, 255, 0.5) !important;
}

.nav-bar-left a.active {
  color: white !important;
}

span.is-open .nav-link {
  color: white !important;
}

.inputType .form-group {
  width: 100%;
}

.inputType .form-group .col-form-label {
  width: 30%;
  text-align: left;
}

.inputType .form-group input {
  width: 70%;
}

button {
  cursor: pointer;
}

.notification-container > span > div:first-of-type {
  margin-top: 65px !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  outline: none;
  margin-bottom: 0;
  vertical-align: text-bottom;
}
.switch input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}

label.switch {
  margin-left: 15px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 17px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #2196f3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.pageable {
  position: relative;
  min-height: 35px;
  margin-bottom: 5px;
}

.pageable-size {
  width: 100px;
  display: inline-block;
}

.pageable-links {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.pageable-links .pageable-links-arrow {
  height: 100%;
  background-color: #fff;
  margin: auto 3px;
  color: #2196f3;
  font-weight: bold;
  border: 2px solid #2196f3;
  text-align: center;
  vertical-align: middle;
}

.pageable-links .pageable-links-disable {
  height: 100%;
  background-color: #fff;
  margin: auto 3px;
  color: #d4d6d8;
  font-weight: bold;
  border: 2px solid #d4d6d8;
  text-align: center;
  vertical-align: middle;
  cursor: no-drop;
}

.pageable-links [class^="pageable-links"]:last-of-type {
  margin-right: 0;
}

.pageable-links .pageable-links-arrow:hover {
  background-color: #2196f3;
  color: #fff;
}

.btn-primary-light {
  background-color: #2196f3 !important;
}

.btn-outline-primary-light {
  color: #2196f3 !important;
  border: 1px solid #2196f3 !important;
}

.btn-outline-primary-light:hover {
  background-color: #2196f3 !important;
  color: white !important;
  border: 1px solid #2196f3 !important;
}

.listOfObject th {
  background-color: moccasin;
  border: none;
  text-align: center;
}

.listOfObject th:not(.action):not(:first-of-type) {
  border-left: 1px solid #999;
}

.listOfObject td {
  border: none;
  text-align: center;
}

.listOfObject td:not(.action):not(:first-of-type) {
  border-left: 1px solid #999;
}

.listOfObject tr:nth-child(even) {
  background: #f7f7f7;
}

.evenBackground div.p-grid:nth-child(even) {
  background: #f7f7f7;
}

.fa-primary-light {
  color: #2196f3 !important;
}

.nav-bar-left {
  float: left;
  height: 94vh;
  background-color: #dc3545;
}

.nav-bar-left a {
  color: rgba(255, 255, 255, 0.5) !important;
}

.nav-bar-left a.active {
  color: white !important;
}

span.is-open .nav-link {
  color: white !important;
}

.inputType .form-group {
  width: 100%;
}

.inputType .form-group .col-form-label {
  width: 35%;
  text-align: left;
}

.inputType .form-group input {
  width: 65%;
}

.radioDiv {
  display: inline-block !important;
  vertical-align: middle;
  padding-left: 5px !important;
}

.radioText {
  margin-left: 15px;
}

.updateComponentForm .form-group .col-form-label {
  width: 200px;
}

.updateComponentForm .form-group .select {
  width: 100% !important;
}

.attribute {
  margin-top: 15px;
}

.attribute .createDialog {
  text-align: left !important;
  display: inline-block;
  margin-left: 10px;
}

.attribute .pageable {
  display: none;
}

.admin-main {
  float: left;
  margin: 50px;
  border: 1px solid #dc3545;
  box-shadow: 13px -4px 25px 0px rgba(0, 0, 0, 0.41);
  border-radius: 3%;
  padding: 15px;
}

.korisnickiProfil-main {
  float: left;
  margin: 0px;
  border: 1px solid #dc3545;
  box-shadow: 13px -4px 25px 0px rgba(0, 0, 0, 0.41);
  border-radius: 3%;
  padding: 15px;
}

td > .form-group {
  margin-bottom: 0 !important;
}

.admin-main > h2,
.admin-main > h4 {
  text-align: center;
}

.no-display {
  display: none;
}

#page-mask {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.radnikEdit > .admin-main > div > .multi-wrapper,
.radnikEdit > .admin-main > div > .multi-wrapper > .headers-multi-wrapper,
.radnikEdit > .admin-main > div > .buttons-multi-wrapper {
  width: 700px;
}

.registriTabletext {
  text-align: center;
}

.autsCard {
  max-width: 70%;
}

.autsDiv {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.autsSkupovni > div {
  padding-left: 0px !important;
}

.scrollDiv {
  max-height: 300px;
  overflow-y: scroll;
}

.tbodyScroll {
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}

.divScroll {
  height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.logoImg {
  width: 260px;
  height: 100px;
  margin-top: 40px;
  margin-left: 60px;
}

.tabBorderColor {
  border-bottom-color: #dee2e6 !important;
}

.dateDiv {
  width: 105px !important;
}

.widthMedium {
  max-width: 400px;
}

.display-linebreak {
  white-space: pre-line;
}

.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.img_logo {
  float: left;
  width: 85px;
  height: 85px;
}

.login_title {
  position: relative;
  top: 18px;
  left: 10px;
}

.td_witdh {
  width: 200px;
}

.blur-text {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.column_info {
  border-radius: 1rem !important;
  font-size: 0.6rem !important;
}

.statistikaUnosaTable {
  width: 1600px;
}

.tab {
  font-size: 1.2rem !important;
}

.bojenje_labele {
  background-color: #e34f44;
  color: #000000;
}

.button_htmlInfo {
  border: none !important;
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  padding-left: 0.4rem !important;
  padding-right: 0.4rem !important;
}

.htmlInfoDialog {
  display: inline-block;
  margin-left: 10px;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.listBoxBroj {
  padding: 10px 0px 0px 5px;
}

.listBoxPolje {
  width: 100%;
  padding: 4px 0px 0px 4px;
}

.alertsBorder {
  border: 1px #dc3545 solid;
  animation: blink 2s;
  animation-iteration-count: 1;
  border-radius: 11px;
}

.p-radiobutton-label {
  margin: 0px 5px 0px 2px;
}

.block-ui {
  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100000;
  background-color: black;
  opacity: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes blink {
  0% {
    border-color: #dc3545;
  }
  50% {
    border-color: #fff;
  }
  100% {
    border-color: #dc3545;
  }
}

.imageDiv {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid rgb(0, 0, 0);
}

.dotDiv {
  text-align: center;
  vertical-align: middle;
  position: absolute;
  border-radius: 50%;
  border: 1px solid rgb(0, 0, 0) !important;
}

@media screen and (max-width: 1800px) {
  .statistikaUnosaTable {
    width: 1550px;
  }
}

@media screen and (max-width: 1680px) {
  .statistikaUnosaTable {
    width: 1450px;
  }
}

@media screen and (max-width: 1366px) {
  .statistikaUnosaTable {
    width: 1130px;
  }
}

@media screen and (max-width: 1024px) {
  .statistikaUnosaTable {
    width: 815px;
  }
}
